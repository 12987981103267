import axios from "axios";
import { config } from "@/config";
import { useState } from "react";
import SelectAccountType from "./SelectAccountType";
import useSetMessageHandler from "./useSetMessageHandler";
import useGetParams from "./useGetParams";
import useCreateFinixToken from "./useCreateFinixToken";

export default function BankForm() {
    const { accountId, ownerType } = useGetParams()
    const [accountNumber, setAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [name, setName] = useState('');
    const {createFinixToken} = useCreateFinixToken()
    useSetMessageHandler(submit, clearForm)

    function clearForm() {
        setAccountNumber('')
        setRoutingNumber('')
        setAccountType('')
        setName('')
    }

    const validateForm = () => {
        let message = ''
        let success = true
        if (!accountNumber) {
            message = "Account number is required";
            success = false
        }
        if (!name) {
            message = "Name is required";
            success = false
        }
        if (!routingNumber) {
            message = "Routing number is required";
            success = false
        } 
        if (accountType === '') {
            message = "accountType is required"
            success = false
        }
        return { success, message }
    };

    async function submit() {
        const validateFormRes = validateForm()
        if (!validateFormRes.success) {
            console.error('form is not valid: ', validateFormRes.message)
            window.parent.postMessage({ message: validateFormRes.message, type: 'tokenization_failure' }, '*');
            return
        }
        const finixTokenRequest = {
            type: "BANK_ACCOUNT",
            country: "USA",
            currency: "USD",
            name: name,
            bank_code: routingNumber,
            account_number: accountNumber,
            account_type: accountType.toUpperCase(),
            address: {
                country: "USA"
            }
        };
        try {
            const token = await createFinixToken(finixTokenRequest)
            let paymentInstrument = await createPaymentInstrument(token)
            window.parent.postMessage({ paymentInstrumentId: paymentInstrument.id, type: 'tokenization_success' }, '*');
        } catch (err: any) {
            console.error(err)
            window.parent.postMessage({ message: err.message, type: 'tokenization_failure' }, '*');
        }
    }

    async function createPaymentInstrument(token: string) {
        const synswiReq = {
            accountId,
            token,
            type: 'bank',
            bank: {
                accountNumber,
                routingNumber,
                accountType,
            },
            owner: {
                name: {
                    fullName: name
                },
                type: ownerType
            }
        }
        const res = await axios.post(`${config.synswiBaseUrl}/accounts/payment-instruments`, synswiReq)
        let paymentInstrument = res.data
        console.log(paymentInstrument)
        return paymentInstrument
    }

    if (!accountId) {
        return (
            <div>accountId is not given</div>
        )
    }

    return (
        <div className="p-1">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Name on account</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none text-sm  placeholder:italic placeholder:text-sm"
                    placeholder="Full name on the account"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Account Number</label>
                <div className="relative">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm text-sm"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}

                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Routing Number</label>
                <div className="relative">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm text-sm"
                        placeholder="Routing Number"
                        value={routingNumber}
                        onChange={(e) => setRoutingNumber(e.target.value)}
                    />
                </div>
            </div>
            <div className="mb-4">
                <SelectAccountType accountType={accountType} setAccountType={setAccountType} />
            </div>
            {/* <button onClick={submit}>test</button> */}
        </div>
    )
}