import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export default function useGetParams() {
    const [searchParams] = useSearchParams();
    const accountId = searchParams.get('accountId');
    const ownerType = searchParams.get('ownerType')

    useEffect(() => {
        console.log('params: ', { accountId, ownerType })
    }, [])
    
    return { accountId, ownerType }
}