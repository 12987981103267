import { useEffect, useRef } from "react";
import { config } from "@/config";
import { useSearchParams } from "react-router-dom";
export default function TokenizationForm() {
    const [searchParams] = useSearchParams();
    const accountId = searchParams.get('accountId'); // Get the value of the 'name' query parameter
    const formRef = useRef<any>(null);

    useEffect(() => {
        const options = {
            showAddress: false,
            showLabels: true,
            showPlaceholders: true,
            placeholders: {
                name: "Cardholder Name",
                number: 'Card Number'
            },
            styles: {
                default: {
                    color: "#000",
                    border: "1px solid #CCCDCF",
                    borderRadius: "4px",
                    padding: "0px 10px",
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif",
                    fontSize: "13px",
                },
            },
        }
        const f = (window as any).Finix.TokenForm('form', options);
        formRef.current = f;
    }, [])

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        const intervalId = setInterval(() => {
            sendHeightToParent() // Update counter every 500ms
        }, 100);
        return () => {
            window.removeEventListener('message', handleMessage);
            clearInterval(intervalId);
        };
    }, []);

    function sendHeightToParent() {
        const height = document.body.scrollHeight;
        window.parent.postMessage({ type: 'set_height', height: height }, '*');
    }

    async function handleMessage(event: any) {
        if (event.data.type === 'submit_form') {
            console.log(event)
            submit()
        }
    }

    const submit = () => {
        const form = formRef.current
        form.submit(config.finixEnvironment, config.applicationId, function (err: any, res: any) {
            if (err) {
                console.log('an error occrured during submiting the form', err)
                return
            }
            const tokenData = res.data || {};
            const token = tokenData.id;
            window.parent.postMessage({ token: token, type: 'tokenization_success' }, '*');
        });
    }

    return (
        <>
            <div id='form' className="flex flex-col p-1" />
            <button onClick={submit}>test</button>
        </>
    );
}