import axios from "axios"
import { config } from "@/config"

export default function useCreateFinixToken() {
    console.log('useCreateFinixToken is rerun')

    async function createFinixToken(finixTokenRequest: any) {
        console.log(finixTokenRequest)
        let res = await axios.post(
            `https://internal.${config.finixEnvironment}-payments-api.com/applications/${config.applicationId}/tokens`,
            finixTokenRequest
        )
        const token = res.data.id
        return token
    }

    return { createFinixToken }
}