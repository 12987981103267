interface Params {
    accountType: string,
    setAccountType: Function
}

const options = [
    { label: "Select an account type", value: '' },
    { label: "Personal Checking", value: 'personal_checking' },
    { label: "Personal Savings", value: 'personal_savings' },
    { label: "Business Checking", value: 'business_checking' },
    { label: "Business Savings", value: 'business_savings' }
]

export default function SelectAccountType(params: Params) {
    function onChange(e: any) {
        const value = e.target.value
        params.setAccountType(value)
    }
    return (
        <>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                Account Type
            </label>
            <select
                value={params.accountType}
                onChange={onChange}
                className="appearance-none bg-white w-full px-3 py-[8.75px] border border-gray-300 rounded-md 
            focus:outline-none italic text-sm"
            >
                {options.map((option: any) =>
                    <option key={option.value} value={option.value}>{option.label}</option>
                )}
            </select>
        </>
    )
}