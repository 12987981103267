import axios from "axios";
import { config } from "@/config";
import { useState } from "react";
import useSetMessageHandler from "./useSetMessageHandler";
import useGetParams from "./useGetParams";
import useCreateFinixToken from "./useCreateFinixToken";
export default function CardForm() {
    const { accountId } = useGetParams()
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [name, setName] = useState('');
    const { createFinixToken } = useCreateFinixToken()

    useSetMessageHandler(submit, clearForm)

    function clearForm() {
        setCardNumber('')
        setExpiry('')
        setCvv('')
        setName('')
    }

    function transformExp(expiry: string) {
        let expMonth, expYear
        let arr = expiry.split('/')
        expMonth = arr[0]
        expYear = arr[1]
        return { expMonth, expYear }
    }

    function handleChangeExpiry(value: string) {
        value = value.replace(/\D/g, '');
        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2, 4);
        }
        setExpiry(value);
    }

    const validateForm = () => {
        let newErrors: any = {};
        if (!cardNumber.match(/^\d{16}$/)) {
            newErrors.cardNumber = "Card number must be 16 digits";
        }
        if (!name) {
            newErrors.name = "Cardholder name is required";
        }
        if (!cvv.match(/^\d{3,4}$/)) {
            newErrors.cvv = "CVV must be 3 or 4 digits";
        }
        if (!expiry) {
            newErrors.expiry = 'Expiration cannot be empty'
        }
        return { success: Object.keys(newErrors).length === 0, errors: newErrors }
    };

    async function submit() {
        const validateFormRes = validateForm()
        if (!validateFormRes.success) {
            console.error('form is not valid: ', validateFormRes.errors)
            console.error({ cardNumber, name, cvv, expiry })
            return
        }
        const { expMonth, expYear } = transformExp(expiry)
        const finixTokenRequest = {
            type: "PAYMENT_CARD",
            country: "USA",
            currency: "USD",
            name: name,
            number: cardNumber,
            expiration_month: parseInt(expMonth),
            expiration_year: parseInt(expYear) + 2000,
            security_code: cvv,
            address: {
                country: "USA"
            }
        };
        const token = await createFinixToken(finixTokenRequest)
        let paymentInstrument = await createPaymentInstrument(token, expMonth, expYear)
        window.parent.postMessage({ paymentInstrumentId: paymentInstrument.id, type: 'tokenization_success' }, '*');
    }

    async function createPaymentInstrument(token: string, expMonth: string, expYear: string) {
        const synswiReq = {
            accountId,
            token,
            type: 'card',
            card: {
                cardNumber: cardNumber,
                expMonth: expMonth,
                expYear: expYear,
                cvv: cvv,
            },
            owner: { 
                name: { fullName: name },
                type: 'buyer'
            }
        }
        const res = await axios.post(`${config.synswiBaseUrl}/accounts/payment-instruments`, synswiReq)
        let paymentInstrument = res.data
        console.log(paymentInstrument)
        return paymentInstrument
    }

    if (!accountId) {
        return (
            <div>accountId is not given</div>
        )
    }

    return (
        <div className="p-1">
            <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Cardholder name</label>
                <input
                    type="text"
                    id="name"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none text-sm  placeholder:italic placeholder:text-sm"
                    placeholder="Full name on card"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700 mb-1">Card information</label>
                <div className="relative">
                    <input
                        type="text"
                        id="cardNumber"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm text-sm"
                        placeholder="Card number"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}

                    />
                    <div className="absolute right-3 top-1/2 -translate-y-1/2 flex">
                        <img src="/networks_image.jpg" className="h-4" />
                    </div>
                </div>
            </div>
            <div className="flex mb-4">
                <div className="w-1/2 pr-2">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm text-sm"
                        placeholder="MM / YY"
                        value={expiry}
                        onChange={(e) => handleChangeExpiry(e.target.value)}
                    />
                </div>
                <div className="w-1/2 pl-2">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm text-sm"
                        placeholder="CVC"
                        value={cvv}
                        onChange={(e) => setCvv(e.target.value)}
                    />
                </div>
            </div>
            {/* <button onClick={submit}>test</button> */}
        </div>
    )
}